import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as Step1 } from "../../assets/step-1.svg";
// import { ReactComponent as Step2 } from "../../assets/step-2.svg";
// import { ReactComponent as Step3 } from "../../assets/step-3.svg";
// import { ReactComponent as Step4 } from "../../assets/step-4.svg";
// import { ReactComponent as Step5 } from "../../assets/step-5.svg";
// import { ReactComponent as Step6 } from "../../assets/step-6.svg";
import { ReactComponent as Timeline } from "../../assets/timeline.svg";

import useStyle from "./styles";

export default function HowItWorksImage() {
  const theme = useTheme();
  const classes = useStyle(theme);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const step1Title = "Register your restaurant";
  const step1Desc =
    "We will provide you with a simple form to fill out so we can collect the necessary information to get started";

  const step3Title = "Let customers scan the QR code";
  const step3Desc =
    "To provide your customers with quick and easy access to tipping, simply encourage them to scan the custom QR code.";

  const step5Title = "Cashless tipping";
  const step5Desc =
    " After your customer has selected a tipping option, they can easily pay their tip by either scanning their credit card or manually entering their payment information.";

  const step2Title = "Get your QR code";
  const step2Desc =
    "Once we've received all the necessary information about your restaurant, we'll get back to you with a customized QR code template just for you.";

  const step4Title = "Pre-set tip amounts";
  const step4Desc =
    " By scanning the QR code, your customers will be instantly taken to Tipper’s digital tip payment feature, where they can select an option from pre-set tip amounts.";

  const step6Title = "Receive Tips";
  const step6Desc =
    " Once your customer has submitted their tip, the funds will be deposited directly into your restaurant's account without any  hassle. Our secure payment processing system ensures that all tips are handled efficiently.";

  return (
    <Box>
      {!mobile ? (
        <Box className={classes.mainContainer}>
          <Box className={classes.groupContainer}>
            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step1Desc}
              stepTitle={step1Title}
            />

            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step3Desc}
              stepTitle={step3Title}
            />

            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step5Desc}
              stepTitle={step5Title}
            />
          </Box>
          <Timeline />
          <Box
            className={`${classes.groupContainer} ${classes.rightContainer}`}
          >
            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step2Desc}
              stepTitle={step2Title}
            />

            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step4Desc}
              stepTitle={step4Title}
            />

            <StepInfo
              classes={classes}
              StepIcon={<Step1 />}
              stepDescription={step6Desc}
              stepTitle={step6Title}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.mobileView}>
          <StepInfo
            stepNumber={1}
            StepIcon={<Step1 />}
            stepDescription={step1Desc}
            stepTitle={step1Title}
            numberColor="#5EE6EB"
            classes={classes}
            mobile={mobile}
          />

          <StepInfo
            stepNumber={2}
            StepIcon={<Step1 />}
            stepDescription={step2Desc}
            stepTitle={step2Title}
            numberColor="#4D77FF"
            classes={classes}
            mobile={mobile}
          />

          <StepInfo
            stepNumber={3}
            StepIcon={<Step1 />}
            stepDescription={step3Desc}
            stepTitle={step3Title}
            numberColor="#56BBF1"
            classes={classes}
            mobile={mobile}
          />

          <StepInfo
            stepNumber={4}
            StepIcon={<Step1 />}
            stepDescription={step4Desc}
            stepTitle={step4Title}
            numberColor="#5EE6EB"
            classes={classes}
            mobile={mobile}
          />

          <StepInfo
            stepNumber={5}
            StepIcon={<Step1 />}
            stepDescription={step5Desc}
            stepTitle={step5Title}
            numberColor="#4D77FF"
            classes={classes}
            mobile={mobile}
          />

          <StepInfo
            stepNumber={6}
            StepIcon={<Step1 />}
            stepDescription={step6Desc}
            stepTitle={step6Title}
            numberColor="#56BBF1"
            classes={classes}
            mobile={mobile}
          />
        </Box>
      )}
    </Box>
  );
}

function StepInfo({
  StepIcon,
  stepDescription,
  stepTitle,
  classes,
  mobile,
  stepNumber,
  numberColor,
}: {
  StepIcon: any;
  stepDescription: string;
  stepTitle: string;
  classes: any;
  mobile?: boolean;
  stepNumber?: number;
  numberColor?: string;
}) {
  return (
    <Box className={classes.stepContainer}>
      <Box className={classes.numberImage}>
        {!mobile ? (
          <></>
        ) : (
          <Typography
            variant="h2"
            style={{
              fontSize: "50px",
              color: numberColor,
              fontWeight: 700,
            }}
            // className={classes.mobileStep}
          >
            {stepNumber}
          </Typography>
        )}
        {StepIcon}
      </Box>
      <Typography variant="h6">{stepTitle}</Typography>
      <Typography variant="h3" className={classes.stepDescription}>
        {stepDescription}
      </Typography>
    </Box>
  );
}
