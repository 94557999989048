import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Grid, TextField, Typography, useMediaQuery } from "@mui/material";

import { firestore } from "../../firebase/config";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import CompanyCard from "./StoreCard";
import { RoundedButton } from "../common/Button";
import { createTheme } from "@material-ui/core/styles";
import useStyle from "../../styles/formStyles";

const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        "&.MuiInputLabel-marginDense": {
          height: "30px",
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});

function StoreList() {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const classs = useStyle();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchStores = async (keyWord) => {
    setLoading(true);

    if (keyWord === "") {
      const allStores = query(
        collection(firestore, "stores"),
        orderBy("companyName", "asc")
      );
      const documentSnapshots = await getDocs(allStores);
      const tempStores = documentSnapshots.docs.map((store) => ({
        ...store.data(),
        id: store.id,
      }));
      setStores(tempStores);
    } else {
      const storesRef = collection(firestore, "stores");
      const storeSnapshot = await getDocs(storesRef);

      var tempStores = [];
      storeSnapshot.forEach((doc) => {
        const companyName = doc.data().companyName.toLowerCase();
        const address = doc.data().address.toLowerCase();
        const branch = doc.data().branch.toLowerCase();
        const country = doc.data().country.toLowerCase();
        var keyWordLower = keyWord.toLowerCase();
        if (
          companyName.includes(keyWordLower) ||
          address.includes(keyWordLower) ||
          branch.includes(keyWordLower) ||
          country.includes(keyWordLower)
        ) {
          const tempStore = { ...doc.data(), id: doc.id };
          tempStores.push(tempStore);
        }
      });
      setStores(tempStores);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchStores("");
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    fetchStores(searchTerm);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Grid container px={mobile ? "10px" : "100px"}>
      <Grid container mb={"60px"}>
        <Grid container justifyContent="space-between">
          <Grid item lg={4}>
            <Typography variant="h1" fontSize={"36px"} fontWeight={800}>
              Find Your Store
            </Typography>
          </Grid>
          <Grid
            item
            display={"flex"}
            lg={8}
            ml={"auto"}
            justifyContent={"flex-end"}
          >
            <TextField
              placeholder="Search by name or location"
              variant="outlined"
              fullWidth
              className={classs.searchTextField}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                marginRight: "14px",
              }}
            />
            <RoundedButton text="Search" onClick={handleSearch} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {loading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container>
              {stores.map((store) => (
                <CompanyCard className="" key={store.id} {...store} />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StoreList;
