import React from "react";

import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { BorderButton } from "../common/Button";

const StoreCard = (props) => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      mr={"25px"}
      sx={{
        width: "296px",
        height: "360px",
        margin: "10px",
        background: "white",
        borderRadius: "16px",
        boxSizing: "border-box",
        position: "relative",
        padding: "20px",
        textAlign: "center",
        maxWidth: "296px",
        "&::before": {
          content: '""',
          borderRadius: "16px",
          backgroundImage: "linear-gradient(#0FD1F2, #56BBF1, #4D77FF)",
          top: "-2px",
          left: "-2px",
          bottom: "-2px",
          right: "-2px",
          position: "absolute",
          zIndex: "-1",
        },
      }}
    >
      <img
        src={props.logo}
        alt="tipper-logo"
        style={{
          maxWidth: "120px",
          minHeight: "120px",
        }}
      />
      <Typography fontSize={"20px"} fontWeight={700} my={"14px"}>
        {props.companyName}
      </Typography>
      <Typography fontSize={"16px"} fontWeight={600} mb={"8px"}>
        {props.address} - {props.branch}
      </Typography>
      <Typography
        fontSize={"12px"}
        fontWeight={700}
        color={"#696A6D"}
        mb={"14px"}
        style={{
          overflow: "auto",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          maxHeight: "38px",
          minHeight: "38px",
        }}
      >
        {props.country}
      </Typography>

      <BorderButton
        text="Tip"
        onClick={() => navigate("/store/" + props.url)}
      />
    </Grid>
  );
};
export default StoreCard;
