import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import tipperLogo from "../../assets/tipper-logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  tipperNavBar: {
    display: "flex",
    backgroundColor: "white",
    boxShadow: "none",
  },
}));

const pages = ["Home", "Store", "FAQs", "Contact Us"];

function ResponsiveAppBar() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function handleNavigate(page: string) {
    const path = page.toLocaleLowerCase();
    navigate(`/${path}`);
  }

  const handleButtonClick = () => {
    // Replace 'target-section' with the actual section ID
    const targetSection = "contact-us";
    // You can use React Router's Link to navigate with the parameter
    // If not using React Router, you can use window.location.href
    window.location.href = `#${targetSection}`;
  };

  return (
    <AppBar
      position="static"
      className={classes.tipperNavBar}
      style={{
        backgroundColor: "white",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={tipperLogo} alt="tipper-logo" />
          <Box sx={{ display: { xs: "flex", md: "none", marginLeft: "auto" } }}>
            <IconButton onClick={handleOpenNavMenu}>
              <GiHamburgerMenu />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu();
                    handleNavigate(page);
                  }}
                >
                  <Typography
                    variant="h2"
                    fontSize={"14px"}
                    fontWeight={500}
                    textAlign="center"
                    style={{
                      color: "black",
                    }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex", marginLeft: "auto" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  if (page === "Contact Us") {
                    handleButtonClick();
                  } else {
                    handleNavigate(page);
                  }
                  handleCloseNavMenu();
                }}
                sx={{
                  color: "black",
                }}
              >
                <Typography variant="h2" fontSize={"14px"} fontWeight={500}>
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
