import React from "react";
import tipperLogo from "../../assets/tipper-inline-logo.svg";
import { Email, Phone } from "@material-ui/icons";
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { useMediaQuery, useTheme, Grid, Typography } from "@mui/material";

function Footer() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      rowGap={2}
      my={mobile ? "16px" : "64px"}
    >
      <Grid item justifyContent="center" alignItems="center">
        <img src={tipperLogo} alt="tipper-logo" />
      </Grid>

      <Grid item>
        <Grid display="flex" justifyContent="center" mb={"19px"}>
          <FaFacebook
            style={{
              color: "#4D77FF",
              fontSize: "36px",
              marginRight: "25px",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(" https://www.facebook.com/tippermenaa/", "_blank")
            }
          />
          <AiFillTwitterCircle
            style={{
              color: "#56BBF1",
              fontSize: "40px",
              marginRight: "25px",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open("https://twitter.com/tippermena", "_blank")
            }
          />
          <AiFillLinkedin
            style={{
              color: "#5EE6EB",
              fontSize: "40px",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/tippermena",
                "_blank"
              )
            }
          />
        </Grid>
        <Typography>
          © Copyright 2023 - Tipper Inc. All rights reserved.
        </Typography>
      </Grid>

      <Grid item>
        <Grid display={"flex"} mb={"14px"}>
          <Email />
          <Typography ml={"10px"}>support@tippermena.com</Typography>
        </Grid>
        <Grid display={"flex"}>
          <Phone />
          <Typography ml={"10px"}>+966 55 699 2811</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
