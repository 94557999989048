import makeStyles from "@mui/styles/makeStyles";

const useStyleMain = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  landingPage: {
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "green",
  },
  text: {
    "@media (min-width: 600px)": {
      fontSize: "20px",
      lineHeight: "1.6",
    },
  },
  "MuiInputLabel-outlined.MuiInputLabel-marginDense": {
    display: "flex",
    alignItems: "center",
    height: "30px",
  },

  mainImage: {
    width: "100%",
    height: "auto",
  },
  row: {
    paddingTop: "50px",
  },

  thirdRow: {
    paddingTop: "50px",
  },

  registerInput: {
    background: "white",
    borderRadius: "30px",
    fontSize: "16px",
    marginBottom: "10px",
    marginRight: "14px",
    width: "300px",
    maxHeight: "54px",
    [`& fieldset`]: {
      borderRadius: "30px",
    },
  },
  regsiterButton: {
    backgroundColor: "#4D77FF",
    borderRadius: "40px",
    fontFamily: "Plus Jakarta Sans",
    color: "white",
    padding: "17px 28px",
    height: "54px",
    textTransform: "unset",
    "&:hover": {
      color: "#4D77FF",
      backgroundColor: "#FCFCFC",
      border: `1px solid #4D77FF`,
    },
  },
  getInTouchButton: {
    color: "#4D77FF",
    borderRadius: "40px",
    padding: "17px 28px",
    height: "54px",
    border: "2px solid #4D77FF",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenText: {
    display: "none",
  },
  logo: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    textAlign: "center",
  },
}));

export default useStyleMain;
