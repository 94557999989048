import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as BlueCircleTick } from "../../assets/blue-circle-tick.svg";
import { ReactComponent as GreenCircleTick } from "../../assets/green-circle-tick.svg";
import { ReactComponent as Star } from "../../assets/star.svg";
import { ReactComponent as Cloud } from "../../assets/cloud.svg";
import { ReactComponent as Spike } from "../../assets/spike.svg";
import { ReactComponent as Lock } from "../../assets/lock.svg";
import useStyle from "./styles";

export default function WhyTipperImage() {
  const theme = useTheme();
  const classes = useStyle(theme);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {mobile ? (
        <Box className={classes.whyItWorksMain}>
          <WhyTipperComponent
            color="#24AEF8"
            label="No more awkward fumbling with loose change or trying to calculate
          the tip."
            CircleTick={<BlueCircleTick />}
          />

          <WhyTipperComponent
            color="#23ECD0"
            label="A modern, convenient way to show your gratitude to your server."
            CircleTick={<GreenCircleTick />}
          />

          <WhyTipperComponent
            color="#24AEF8"
            label="Secure and easy-to-use interface for tipping on the go."
            CircleTick={<BlueCircleTick />}
          />

          <WhyTipperComponent
            color="#23ECD0"
            label="Tipper is widely accepted across a growing network of restaurants and cafes."
            CircleTick={<GreenCircleTick />}
          />
        </Box>
      ) : (
        <Box className={classes.whyItWorksDesktop}>
          <Box className={classes.whyItWorksMain}>
            <WhyTipperComponent
              color="#24AEF8"
              label="No more awkward fumbling with loose change or trying to calculate
            the tip."
              CircleTick={<BlueCircleTick />}
            />
            <Cloud />
          </Box>
          <Box className={classes.whyItWorksMain}>
            <Star />
            <WhyTipperComponent
              color="#23ECD0"
              label="A modern, convenient way to show your gratitude to your server."
              CircleTick={<GreenCircleTick />}
            />
          </Box>

          <Box className={classes.whyItWorksMain}>
            <WhyTipperComponent
              color="#24AEF8"
              label="Secure and easy-to-use interface for tipping on the go."
              CircleTick={<BlueCircleTick />}
            />
            <Lock />
          </Box>

          <Box className={classes.whyItWorksMain}>
            <Spike />
            <WhyTipperComponent
              color="#23ECD0"
              label="Tipper is widely accepted across a growing network of restaurants and cafes."
              CircleTick={<GreenCircleTick />}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

function WhyTipperComponent({
  label,
  color,
  CircleTick,
}: {
  label: string;
  color: string;
  CircleTick: any;
}) {
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <Box
      style={{
        border: `2px solid ${color}`,
      }}
      className={classes.whyItWorksMainComp}
    >
      <Box className={classes.TickTextContainer}>
        <Box className={classes.circleTick}>{CircleTick}</Box>
        <Typography
          variant="h3"
          className={classes.WhyItWorksText}
          style={{
            lineHeight: "25px",
            fontWeight: 800,
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}
