import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import LandingPage from "./pages/LandingPage";
import FAQs from "./pages/FAQs";
import Store from "./pages/Store";
import "./App.css";
import ContactUs from "./components/landingPage/contactUs/ContactUs";
import Footer from "./components/footer/Footer";
import PayTip from "./components/payment/PayTip";
import Feedback from "./components/payment/Feedback";
import ThemeColorProvider from "./ThemeColorProvider";
import TapAccountSetup from "./pages/TapAccountSetup";
import PlatformYouTrustImage from "./components/Images/PlatformYouTrustImage";

function App() {
  const location = useLocation();
  const toExlude = [
    "/store/",
    "/card-details",
    "/feedback",
    "/tapAccountSetup",
  ];

  return (
    <div className="tipper-app">
      <ThemeColorProvider>
        <header>
          <Navbar />
        </header>
        <div className="routes-contact-us-wrapper">
          <main className="tipper-tabs">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/store" element={<Store />} />
              <Route path="/store/*" element={<PayTip />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/tapAccountSetup" element={<TapAccountSetup />} />
              <Route path="/test" element={<PlatformYouTrustImage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>

          {toExlude.find(
            (element) => location.pathname.includes(element) === true
          ) ? null : (
            <ContactUs className="contact-us" />
          )}
        </div>
        {toExlude.find(
          (element) => location.pathname.includes(element) === true
        ) ? null : (
          <Footer />
        )}
      </ThemeColorProvider>
    </div>
  );
}

export default App;
