import "@fontsource/plus-jakarta-sans";
import "typeface-quicksand";

const getDesignTokens = () => ({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        fontSize: 12,
        fontWeight: 600,
      },
    },
  },
  palette: {
    primary: {
      main: "#696A6D",
    },
  },
  typography: {
    fontFamily: "'Plus Jakarta Sans', 'Quicksand', ariel",
    h1: {
      fontFamily: "Plus Jakarta Sans, Arial",
    },
    h2: {
      fontWeight: 600,
      fontSize: "20px",
      fontFamily: "Quicksand, Arial, sans-serif",
    },
    h3: {
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Quicksand, Arial, sans-serif",
    },
    h6: {
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Plus Jakarta Sans, Arial, sans-serif",
    },
  },
});

export default getDesignTokens;
