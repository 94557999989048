import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase/config";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  Typography,
  Grid,
  Box,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material";
import GradientButton from "../common/Button";
import axios from "axios";

export default function PayTip() {
  const [storeData, setStoreData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedTip, setSelectedTip] = useState(10);
  const [customTip, setCustomTip] = useState("");
  const [userData, setUserData] = useState(false);
  const [device, setDevice] = useState({
    platform: "",
    userAgent: "",
    language: "",
    languages: "",
  });
  const [refferalSource, setRefferalSource] = useState(false);

  useEffect(() => {
    if (customTip === storeData.currency || customTip === "")
      setSelectedTip(10);
    else setSelectedTip(0);
  }, [customTip, storeData.currency]);

  const handleSelect = (amount) => {
    setSelectedTip(amount);
  };

  const handlePayment = async (amount, currency, storeName) => {
    setPaymentLoading(true);
    const response = await fetch(
      "https://rapid-compound-388619.el.r.appspot.com/pay",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount,
          currency: currency,
          storeName: storeName,
        }),
        mode: "cors",
      }
    ).catch((err) => {
      console.log(err);
    });
    const result = await response.json();

    addDoc(collection(firestore, "transactions"), {
      ...result,
      userData: userData,
      device: device,
      referrer: refferalSource,
      storeUrl: storeData.url,
      postedOn: serverTimestamp(),
    });

    window.location.replace(result.transaction.url, "_blank", "noreferrer");
    setPaymentLoading(false);
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setUserData(res.data);
  };

  useEffect(() => {
    setDevice({
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      language: navigator.language,
      languages: navigator.languages,
    });
    setRefferalSource(document.referrer);
    getData();
  }, []);

  const fetchStore = async (url) => {
    setLoading(true);

    const storesRef = collection(firestore, "stores");
    const storeSnapshot = await getDocs(storesRef);

    storeSnapshot.forEach((doc) => {
      if (url === doc.data().url) {
        const tempStore = { ...doc.data(), id: doc.id };
        setStoreData(tempStore);
        setLoading(false);
        return;
      }
    });
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const url = currentURL.substring(currentURL.lastIndexOf("/") + 1);

    fetchStore(url);
  }, []);

  return (
    <Grid container justifyContent="center" display={"flex"}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          direction="column"
          // justifyContent="space-evenly"
          alignItems="center"
          sx={{
            width: { md: "390px" },
          }}
        >
          <img
            src={storeData.logo}
            style={{
              width: "160px",
              height: "140px",
            }}
            alt="CompanyCard"
          />
          <Typography fontSize={"24px"} fontWeight={700}>
            {storeData.companyName}
          </Typography>

          <Typography fontSize={"14px"} fontWeight={600} mt="24px">
            {storeData.address} - {storeData.branch}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={500}
            align="center"
            color="#696A6D"
          >
            {storeData.country}
          </Typography>

          <Grid>
            <Typography
              fontSize={"36px"}
              fontWeight={600}
              mt={"24px"}
              mb="20px"
              align="center"
            >
              Pay Tip
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TipAmount
                  amount={10}
                  selected={selectedTip}
                  currency={storeData.currency}
                  handleSelect={handleSelect}
                  customTip={customTip}
                />
              </Grid>
              <Grid item xs={3}>
                <TipAmount
                  amount={storeData.tipSecond}
                  selected={selectedTip}
                  currency={storeData.currency}
                  handleSelect={handleSelect}
                  customTip={customTip}
                />
              </Grid>
              <Grid item xs={3}>
                <TipAmount
                  amount={storeData.tipThird}
                  selected={selectedTip}
                  currency={storeData.currency}
                  handleSelect={handleSelect}
                  customTip={customTip}
                />
              </Grid>
              <Grid item xs={3}>
                <TipAmount
                  amount={storeData.tipMax}
                  selected={selectedTip}
                  currency={storeData.currency}
                  handleSelect={handleSelect}
                  customTip={customTip}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              mb="20px"
            >
              <Box display="flex" alignItems="center" my="20px">
                <Divider flexItem style={{ flexGrow: 1 }} />
                <Typography variant="body1" align="center">
                  or
                </Typography>
                <Divider flexItem style={{ flexGrow: 1 }} />
              </Box>

              <TextField
                // variant="text"
                variant="standard"
                sx={{
                  "& .MuiInput-underline": {
                    "&:before, &:after": {
                      borderBottom: "none", // Remove the underline in all states
                    },
                  },
                  "& .MuiInput-underline:before & .MuiInput-underline:after": {
                    borderBottom: "none", // Remove the underline in all states
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "none", // Remove the underline in all states
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the underline when hovering (optional)
                  },
                  "& input": {
                    textAlign: "center", // Center align the text
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#4D77FF",
                    opacity: 1, // Set the opacity to 1 to remove the transparency
                    fontWeight: 600,
                  },
                  backgroundColor: "#5EE6EB40",
                  color: "#4D77FF",
                  boxShadow: "none",
                  textTransform: "none",
                  borderRadius: "14px",
                  fontWeight: 600,
                  fontSize: "14px",
                  // padding: "12px 74px",
                  width: "280px",
                  height: "39px",
                  justifyContent: "center",
                }}
                value={customTip}
                placeholder=" Add a custom tip"
                onChange={(event) => {
                  const input = event.target.value;
                  const numericInput = input.replace(/[^0-9$]/g, ""); // Remove non-numeric characters

                  if (numericInput > 0)
                    setCustomTip(storeData.currency + " " + numericInput);
                  else setCustomTip(storeData.currency + numericInput);

                  // setSelectedTip(0);
                }}
              />
            </Grid>

            <Grid display="flex" justifyContent="center" alignItems="center">
              <GradientButton
                disabled={paymentLoading}
                text={paymentLoading ? <CircularProgress /> : "Pay Now"}
                onClick={() =>
                  //add payment api here
                  handlePayment(
                    customTip === "" || customTip === storeData.currency
                      ? selectedTip
                      : parseFloat(customTip.replace(/[^0-9.-]+/g, "")) || 0,
                    storeData.currency,
                    storeData.storeName
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function TipAmount({ amount, selected, currency, handleSelect, customTip }) {
  return (
    <Box
      sx={{
        border: selected === amount ? "" : "1px solid #DDDDDD",
        borderRadius: "100%",
        padding: "27.5px",
        justifyContent: "center",
        backgroundColor: selected === amount ? "#4D77FF" : "",
        color: selected === amount ? "#FFFFFF" : "#303434",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      display="flex"
      onClick={() => {
        if (customTip === currency) handleSelect(amount);
        if (customTip === "") handleSelect(amount);
      }}
    >
      <Typography alignContent={"center"} fontSize={"20px"} fontWeight={700}>
        {currency}
        {amount}
      </Typography>
    </Box>
  );
}
