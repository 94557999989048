import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    gap: 60,
  },

  groupContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 150,
  },

  rightContainer: {
    marginTop: 110,
  },

  stepContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: 360,
  },

  stepDescription: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    lineHeight: "28px !important",
  },

  mobileView: {
    display: "flex",
    gap: 16,
    flexDirection: "column",
    alignItems: "center",
  },

  mobileStep: {
    fontSize: "50px",
  },

  numberImage: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },

  whyItWorksMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 24,
  },

  whyItWorksDesktop: {
    display: "flex",
    gap: 32,
    justifyContent: "center",
    alignItems: "center",
  },

  whyItWorksMainComp: {
    borderRadius: "16px",
    width: 280,
    height: 160,
    display: "flex",
    alignItems: "center",
  },

  TickTextContainer: {
    padding: 28,
    position: "relative",
  },

  circleTick: {
    position: "absolute",
    top: -30,
    left: -15,
  },

  WhyItWorksText: {
    lineHeight: "25px",
    fontWeight: 800,
  },

  responsiveImage: {
    width: "100%", // Set the initial width to 100%
    height: "auto", // Maintain aspect ratio
    [theme.breakpoints.up("sm")]: {
      width: "50%", // Resize to 50% on small screens and larger
    },
    [theme.breakpoints.up("md")]: {
      width: "30%", // Resize to 30% on medium screens and larger
    },
    // Add more breakpoints and width values as needed
  },
}));

export default useStyle;
