import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import ContactUsForm from "./ContactUsForm";
import useStyle from "./styles";

const ContactUs = () => {
  const classes = useStyle();

  useEffect(() => {
    // Get the section parameter from the URL
    const section = window.location.hash.substring(1);
    const targetSectionElement = document.getElementById(section);
    if (targetSectionElement) {
      targetSectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div id="contact-us">
      <Grid container alignItems="center" justifyContent="center">
        <Box className={classes.canvas}>
          <ContactUsForm />
        </Box>
      </Grid>
    </div>
  );
};

export default ContactUs;
