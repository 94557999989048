import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import GradientButton from "../common/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { firestore } from "../../firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Snackbar from "@material-ui/core/Snackbar";
import tipperLogo from "../../assets/tipper-inline-logo.svg";
import { FaStar } from "react-icons/fa";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Feedback() {
  const [feedback, setFeedback] = useState({});
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(false);
  const [device, setDevice] = useState({
    platform: "",
    userAgent: "",
    language: "",
    languages: "",
  });
  const [refferalSource, setRefferalSource] = useState(false);
  const [chargeId, setChargeId] = useState("");
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (e) => {
    e.preventDefault();
    addDoc(collection(firestore, "feedback"), {
      ...feedback,
      userData: userData,
      device: device,
      referrer: refferalSource,
      chargeId: chargeId,
      postedOn: serverTimestamp(),
    });
    setOpen(true);
    var message = document.getElementById("message");
    message.value = "";

    setFeedback({});
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setUserData(res.data);
  };

  useEffect(() => {
    setDevice({
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      language: navigator.language,
      languages: navigator.languages,
    });
    setRefferalSource(document.referrer);
    getData();
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const id = currentURL.substring(currentURL.lastIndexOf("=") + 1);
    setChargeId(id);
  }, []);

  return (
    <Grid
      container
      justifyContent={"center"}
      p={mobile ? "24px 16px" : "68px 78px"}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          width: { md: "390px" },
        }}
      >
        <img src={tipperLogo} alt="tipper-logo" />

        <Typography
          fontSize={"36px"}
          fontWeight={600}
          mt={"24px"}
          align="center"
        >
          Rate Us
        </Typography>
        <Typography
          fontSize={"16px"}
          fontWeight={500}
          mt={"24px"}
          mb="24px"
          align="center"
          color="#666666"
        >
          Your feedback is valuable to us as we strive to continually improve.
        </Typography>

        <Grid
          container
          direction={"row"}
          rowGap={5}
          justifyContent={"center"}
          required
          // mb="24px"
        >
          {[1, 2, 3, 4, 5].map((index) => (
            <Grid
              key={index}
              onClick={() => setFeedback({ ...feedback, stars: index })}
            >
              <FaStar
                fontSize={44}
                style={{
                  color: index <= feedback.stars ? "#FFCC00" : "#EFEFF4",
                  marginRight: "15px",
                }}
              />
            </Grid>
          ))}
        </Grid>
        <TextField
          id="message"
          placeholder="Addtional comments..."
          required
          multiline
          rows={7}
          style={{
            backgroundColor: "#EFEFF480",
            borderRadius: "8px",
            color: "#666666",
            margin: "24px 0",
            width: "330px",
          }}
          onChange={(event) => {
            setFeedback({ ...feedback, comment: event.target.value });
          }}
        />

        <Grid display="flex" justifyContent="center" alignItems="center">
          <GradientButton onClick={handleSubmit} text="Submit Feedback" />
        </Grid>
        <Grid container item xs={12}>
          <Snackbar
            open={open}
            style={{
              borderRadius: "25px",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: 0,
            }}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Feedback Submitted!
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Grid>
  );
}
