import { Button } from "@mui/material";
import React from "react";

export function GradientButton({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <Button
      variant="text"
      sx={{
        background: `linear-gradient(268.37deg, #4D77FF -4.49%, #56BBF1 47.21%, #5EE6EB 100%)`,
        border: 0,
        borderRadius: "14px",
        boxShadow: `0 3px 5px 2px rgba(0, 0, 0, 0.2)`,
        color: "white",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "16px",
        padding: "12px 55px 12px 55px",
        width: "280px",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export default GradientButton;

export function RoundedButton({
  text,
  onClick,
  customPadding,
}: {
  text: string;
  onClick?: () => void;
  customPadding?: boolean;
}) {
  return (
    <Button
      variant="text"
      type="submit"
      sx={{
        background: "#4D77FF",
        border: 0,
        borderRadius: "40px",
        color: "white",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "16px",
        padding: customPadding ? "12px 28px 12px 28px" : "12px 55px 12px 55px",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

export function BorderButton({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) {
  return (
    <Button
      variant="text"
      type="submit"
      sx={{
        borderRadius: "40px",
        border: "2px solid #4D77FF",
        color: "#4D77FF",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "16px",
        padding: "12px 55px 12px 55px",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
