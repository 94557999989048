import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  ListItemIcon,
  useTheme,
  Grid,
  useMediaQuery,
} from "@mui/material";
import "./faqs.css";
import ArrowDown from "../assets/arrow-down.png";
import useStyle from "../styles/formStyles";

const faqs = [
  {
    question: "How does Tipper work?",
    answer: `Tipper is an online platform that enables customers to leave tips for servers of any industry using their smartphones. Businesses can register their branches with Tipper and generate a unique QR code for each location. Customers can then scan the QR code using their device, which will direct them to a tipping interface where they can choose preset tip amounts or enter a custom tip.`,
  },

  {
    question: "How can I register my business with Tipper?",
    answer: `To register your business with Tipper, please visit our 'Contact Us'. We will reach out to you and ask for the required information about your business, such as its name, location, and contact details. Once you submit the registration form, our team will review and verify the information before approving your registration.`,
  },
  {
    question: "How do customers leave tips using Tipper?",
    answer: `To leave a tip using Tipper, follow these steps:
Visit the business physical location or website you wish to tip and locate the Tipper QR code displayed in a prominent area.
Scan the QR code. 
You will be directed to the tipping interface where you can select from preset tip amounts or enter a custom tip.
Confirm your tip amount and complete the transaction securely.`,
  },
  {
    question: "Is Tipper a secure platform for online tipping?",
    answer: `Yes, Tipper prioritizes the security and privacy of our users. We use Tap Payments for all payments involved, which is known for its industry-standard security measures to protect your personal information and ensure secure transactions`,
  },
  {
    question: "What are the advantages of cashless tipping with Tipper?",
    answer: `Cashless tipping with Tipper offers several benefits:
CONVENIENCE: You no longer need to carry cash or worry about having exact change to leave a tip.
SAFETY: By eliminating the need for physical cash, you reduce the risk of loss or theft.
EFFICIENCY: Tipper provides a quick and seamless process for leaving tips, making it easier for both customers and the business.`,
  },
  {
    question: "Can I use Tipper for tipping at any place?",
    answer: `Tipper aims to partner with a wide range of businesses. While not all businesses may be on the platform, we are continuously expanding our network. You can check the Tipper website for a list of participating businesses around you!`,
  },
  {
    question: "Can I tip any amount I want?",
    answer: `Within the limits set by the business, you can enter any tip amount you desire. Some businesses may have minimum or maximum limits for tips to ensure fairness and consistency.`,
  },
  {
    question: "How can I track my tips as a business owner?",
    answer: `As a registered business owner on Tipper, you will have access to a dashboard where you can track and manage the tips received. The dashboard provides insights into tip amounts, trends, and other relevant data to help you effectively manage your tipping system.`,
  },
  {
    question: "What if I accidentally tip the wrong amount?",
    answer: `If you accidentally enter the wrong tip amount, please contact our support team as soon as possible. We will assist you in rectifying the situation and ensuring that the correct tip amount is processed.`,
  },
  {
    question: "Are tips distributed directly to business staff?",
    answer: `The distribution of tips depends on the policies established by each individual business. Tipper acts as a facilitator, providing a platform for tipping, but the distribution process is determined by the business. We recommend reaching out to the specific business to inquire about their tipping policies and practices.`,
  },
  {
    question:
      "What country can my business be in to qualify for signing up for Tipper?",
    answer: `Countries that are supported by Tipper for now are Saudi Arabia, Kuwait, Bahrain, UAE, Oman, Qatar, Egypt, Jordan & Lebanon. Keep updated to know more about our supported regions!`,
  },
  {
    question: "Which currencies are supported by Tipper?",
    answer: `Currently, we support 10 currencies:
UAE Dirham AED
Bahraini Dinar BHD
Egyptian Pound EGP
Euro EUR
UK Pound Sterling GBP
Kuwaiti Dinar KWD
Omani Riyal OMR
Qatari Riyal QAR
Saudi Riyal SAR
US Dollar USD`,
  },
];

export default function FAQs() {
  const classes = useStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item>
        <Typography variant="h1" fontSize={"36px"} mb="36.5px" fontWeight={700}>
          Got Any Questions
        </Typography>
      </Grid>
      <Grid item width={mobile ? "100%" : "50%"}>
        {faqs.map((x) => {
          return (
            <Accordion
              style={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary>
                <ListItemIcon>
                  <img
                    src={ArrowDown}
                    alt="banner"
                    width="40px"
                    height="40px"
                  />
                </ListItemIcon>
                <Typography
                  variant="h2"
                  fontSize={"20px"}
                  fontWeight={600}
                  className={classes.faqAccordion}
                >
                  {x.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" className={classes.faqAnswers}>
                  {x.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
}
