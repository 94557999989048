import { Typography, Box, TextField, Grid } from "@mui/material";
import React, { useState } from "react";

export default function TapAccountSetup() {
  const [formData, setFormData] = useState({
    email: "",
    business_name: "",
    type: "",
    legal_name: "",
    is_licensed: false,
    license_type: "",
    license_number: "",
    is_non_profit: true,
    registered_country: "",
    currency: "",
    business_tax_number: "",
    license_issue_date: Date(),
    license_expiry_date: Date(),
    iban: "",
    swift_code: "",
    account_number: "",
    recipient_name: "",
    address_line_1: "",
    address_line_2: "",
    district: "",
    state: "",
    zip_code: "",
    country: "",
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    supervisor_email: "",
    supervisor_phone_number: "",
    nationality: "",
    date_of_birth: Date(),
    id_number: "",
    id_issue_date: Date(),
    id_expiry_date: Date(),
    business_sector: "",
    website: "",
    social_media_urls: "",
    tag_line: "",
    about: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <Box padding={2}>
      <Typography textAlign={"center"}>
        Welcome to Tipper! We are happy to see you here! To get started on the
        journey of receiving cashless Tips using Tipper for your business,
        please fill the following form. The information provided will only be
        used to create a payment account (currently only Tap Payments) Once you
        send us the following information, we will start the process of making
        your account, and as soon as our team completes the KYC, we will send
        you a customised QR code which you can place online or at a physical
        location! Your users will be able to send you Tips using the QR code,
        and the payments will be sent to your Tap Account ASAP. ** Note: There
        is 17.5% charge per transaction, you will receive 82.5% of the actual
        tip amount in your Tap account, and it takes 3-5 days to reach your bank
        account In case of any questions or queries, feel free to reach out to
        us on support@tippermena.com and we will get back to you ASAP Happy
        Tipping!
      </Typography>

      <Grid container marginTop={5} direction={"column"} gap={2}>
        <Grid item display="flex" alignItems="center" gap={2}>
          <Typography>Name:</Typography>
          <TextField
            id="name"
            placeholder="Enter your name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item display="flex" alignItems="center" gap={2}>
          <Typography>Business Name:</Typography>
          <TextField
            id="business_name"
            placeholder="Enter your business name"
            variant="outlined"
            value={formData.business_name}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item display="flex" alignItems="center" gap={2}>
          <Typography>Type:</Typography>
          <TextField
            id="type"
            placeholder="Enter your type"
            variant="outlined"
            value={formData.type}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item display="flex" alignItems="center" gap={2}>
          <Typography>Legal Name:</Typography>
          <TextField
            id="legal_name"
            placeholder="Enter your legal name"
            variant="outlined"
            value={formData.legal_name}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
    </Box>
  );
}
