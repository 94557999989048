import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  canvas: {
    display: "flex",
    alignItems: "center",
    background: "#BFF5F7",
    borderRadius: "20px",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    marginTop: "140px",
    minWidth: "360px",
    maxWidth: "730px",
  },
}));

export default useStyle;
