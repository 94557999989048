import React from "react";
import mainBg from "../../assets/platform_you_trust.svg";
import { ReactComponent as BlueStar } from "../../assets/blue-star.svg";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import useStyle from "./styles";

export default function PlatformYouTrustImage() {
  const theme = useTheme();
  const classes = useStyle(theme);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {mobile ? (
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <LargeTag
              borderColor="#0FD1F2"
              label="You can use Tipper for all kinds of transactions, from coffee
              shops and cafes to fine dining restaurants and more"
              rotation="13.96"
              margin="0 0 0 100px"
            />
            <LargeTag
              borderColor="#23ECD0"
              label="Choose from pre-set tip amounts, average tip amounts for the
            place or enter a custom amount"
              rotation="-5.87"
              margin="0 100px 0 0"
            />
          </Box>
          <Box
            style={{
              position: "relative",
            }}
          >
            <SmallTag
              label=" Server Support"
              bgColor="#56BBF1"
              rotation="rotate(-11.07deg)"
              labelColor="#FFFFFF"
              left={210}
            />
            <SmallTag
              label="Custom Tips"
              bgColor="#F2FA5A"
              rotation="rotate(-12deg)"
              labelColor="#161E29"
              top={165}
            />

            <SmallTag
              label="Diversity"
              bgColor="#5EE6EB"
              rotation="rotate(7.49deg)"
              labelColor="#FFFFFF"
              left={190}
              top={190}
            />
            <img
              src={mainBg}
              className={classes.responsiveImage}
              alt="main img"
            ></img>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <LargeTag
              borderColor="#4675FF"
              label="Customize the QR code design, such a logo or changing the colors"
              rotation="5.4"
              minHeight={80}
              margin="0 100px 0 0"
            />

            <LargeTag
              borderColor="#EDFD26"
              label=" Submit your tip and feel good knowing that you've helped support
              your server"
              rotation="-5.99"
              margin="0 0 0 100px"
            />
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              position: "relative",
            }}
          >
            <LargeTag
              borderColor="#23ECD0"
              label="Choose from pre-set tip amounts, average tip amounts for the
            place or enter a custom amount"
              top={-50}
              left={-100}
              rotation="-5.87"
            />

            <LargeTag
              borderColor="#0FD1F2"
              label="You can use Tipper for all kinds of transactions, from coffee
              shops and cafes to fine dining restaurants and more"
              top={-100}
              right={-100}
              rotation="13.96"
            />

            <LargeTag
              borderColor="#4675FF"
              label="Customize the QR code design, such a logo or changing the colors"
              top={360}
              left={-100}
              rotation="5.4"
              minHeight={80}
            />

            <LargeTag
              borderColor="#EDFD26"
              label=" Submit your tip and feel good knowing that you've helped support
              your server"
              top={320}
              right={-160}
              rotation="-5.99"
            />
            <SmallTag
              label="Custom Tips"
              bgColor="#F2FA5A"
              rotation="rotate(-12deg)"
              labelColor="#161E29"
              left={-150}
              top={185}
            />

            <SmallTag
              label="Diversity"
              bgColor="#5EE6EB"
              rotation="rotate(12.31deg)"
              labelColor="#FFFFFF"
              left={280}
              top={410}
            />

            <SmallTag
              label="Server Support"
              bgColor="#56BBF1"
              rotation="rotate(11.72deg)"
              labelColor="#FFFFFF"
              left={600}
              top={176}
            />
            <img src={mainBg} alt="main img"></img>
          </Box>
        </Box>
      )}
    </>
  );
}

type LargeTagProps = {
  borderColor: string;
  rotation?: string;
  label: string;
  left?: number;
  right?: number;
  top?: number;
  minHeight?: number;
  margin?: string;
};
function LargeTag({
  borderColor,
  rotation,
  label,
  right,
  left,
  top,
  minHeight,
  margin,
}: LargeTagProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      style={{
        border: `2px solid ${borderColor}`,
        borderRadius: 16,
        minHeight: minHeight,
        padding: mobile ? 8 : 32,
        maxWidth: 220,
        textAlign: "center",
        backgroundColor: "white",
        position: top ? "absolute" : undefined,
        top: top,
        left: left,
        right: right,
        transform: rotation ? `rotate(${rotation}deg)` : undefined,
        zIndex: 1,
        margin: margin,
      }}
    >
      <Typography
        variant="h3"
        style={{
          lineHeight: "25px",
          fontWeight: 700,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

type SmallTagProps = {
  bgColor: string;
  rotation: string;
  label: string;
  labelColor: string;
  left?: number;
  top?: number;
};

function SmallTag({
  bgColor,
  rotation,
  label,
  labelColor,
  left,
  top,
}: SmallTagProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid white",
        gap: mobile ? 10 : 20,
        backgroundColor: bgColor,
        borderRadius: 30,
        minHeight: mobile ? 30 : 48,
        minWidth: mobile ? 120 : 220,
        textAlign: "center",
        position: "absolute",
        top: top,
        left: left,
        transform: rotation,
        padding: "0 16px",
      }}
    >
      <BlueStar />
      <Typography
        style={{
          fontWeight: 1000,
          color: labelColor,
          fontSize: mobile ? 10 : "",
        }}
      >
        {label}
      </Typography>
      <BlueStar />
    </Box>
  );
}
