import React, { useMemo } from "react";
import { GlobalStyles } from "@mui/material";
import getDesignTokens from "./theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const themeContext = React.createContext();

export default function ThemeColorProvider({ children }) {
  const theme = useMemo(() => createTheme(getDesignTokens()), []);

  return (
    <themeContext.Provider>
      <GlobalStyles />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </themeContext.Provider>
  );
}
