import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { firestore } from "../../../firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import useStyle from "../../../styles/formStyles";
import { RoundedButton } from "../../common/Button";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactUsForm = () => {
  const classes = useStyle();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(false);
  const [device, setDevice] = useState({
    platform: "",
    userAgent: "",
    language: "",
    languages: "",
  });
  const [refferalSource, setRefferalSource] = useState(false);

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (e) => {
    e.preventDefault();
    addDoc(collection(firestore, "queries"), {
      name: name,
      message: message,
      email: email,
      postedOn: serverTimestamp(),
      userData: userData,
      device: device,
      referrer: refferalSource,
    });
    setOpen(true);

    setName("");
    setEmail("");
    setMessage("");
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setUserData(res.data);
  };

  useEffect(() => {
    setDevice({
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      language: navigator.language,
      languages: navigator.languages,
    });
    setRefferalSource(document.referrer);
    getData();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid p={mobile ? "24px 16px" : "68px 78px"}>
      <Typography variant="h1" fontSize={"36px"} fontWeight={700} mb={"28px"}>
        Contact Us
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item lg={6} xs={12}>
            <TextField
              id="name"
              placeholder="Enter your name"
              variant="outlined"
              className={classes.contactUsTextField}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ marginBottom: mobile ? "14px" : "" }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              id="email"
              className={classes.contactUsTextField}
              variant="outlined"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ marginBottom: "14px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message"
              variant="outlined"
              placeholder="Enter your message"
              className={classes.contactUsTextField}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={6}
              required
            />
          </Grid>
          <Grid container justifyContent="center" mt={"28px"}>
            <RoundedButton text="Get in Touch" />
          </Grid>
          <Grid container item xs={12}>
            <Snackbar
              open={open}
              style={{
                borderRadius: "25px",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
              }}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                Message sent!
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default ContactUsForm;
