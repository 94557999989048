import React from "react";
import StoreList from "../components/store/StoreList";

// const useStyles = makeStyles((theme) => ({
//   store: {
//     flexWrap: "wrap",
//   },
// }));

export default function Store() {
  // const classes = useStyles();

  return (
    <div
    //  className={classes.store}
    >
      <StoreList />
    </div>
  );
}
