import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    "&. MuiTextField-root": {
      backgroundColor: "white",
      borderRadius: "40px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 8px",
      margingBottom: "2px",
      backgroundColor: "white",
      borderRadius: "40px",
      fontWeight: 400,
      width: "100%",
      "&:focus": {
        borderRadius: "6px",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: "white",
      },
      "&:hover": {
        borderRadius: "6px",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: "white",
      },
    },
    "& .MuiAutocomplete-root": {
      "&:hover": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        borderRadius: "6px",
        backgroundColor: "white",
      },
      "&:focus": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        borderRadius: "6px",
        backgroundColor: "white",
      },
      "& .MuiInput-input": {
        padding: "6px 8px",
        backgroundColor: "white",
      },
      "& .MuiInput-root": {
        backgroundColor: "white",
      },
    },
  },

  input: {
    "&::placeholder": {
      fontWeight: 400,
    },
  },
  desc: {
    "& .MuiInputBase-input": {
      fontWeight: 400,
      width: "100%",
      margingBottom: "2px",
      backgroundColor: "white",
      borderRadius: "40px",
    },
  },

  searchTextField: {
    background: "white",
    borderRadius: "30px",
    fontSize: "16px",
    marginBottom: "10px",
    maxWidth: "380px",
    [`& fieldset`]: {
      borderRadius: "30px",
    },
  },

  contactUsTextField: {
    background: "white",
    borderRadius: "30px",
    fontSize: "16px",
    marginBottom: "10px",
    width: "100%",
    [`& fieldset`]: {
      borderRadius: "30px",
      border: "none",
    },
  },

  faqAccordion: {
    display: "flex",
    alignItems: "center",
    flex: "100%",
    fontSize: "36px",
    fontWeight: 700,
    boxShadow: "none",
  },

  faqAnswers: {
    whiteSpace: "pre-wrap",
  },
}));

export default useStyle;
