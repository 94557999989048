import { useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import mainImage from "../assets/landing-page-main.png";
import React, { useState } from "react";
import { firestore } from "../firebase/config";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import useStyle from "../styles/formStyles";
import useStyleMain from "./styles";
import { BorderButton, RoundedButton } from "../components/common/Button";
import Slider from "react-infinite-logo-slider";
import HowItWorksImage from "../components/Images/HowItWorksImage";
import PlatformYouTrustImage from "../components/Images/PlatformYouTrustImage";
import WhyTipperImage from "../components/Images/WhyTipperImage";

const LandingPage = () => {
  const classes = useStyleMain();
  const classes1 = useStyle();
  const [email, setEmail] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [logos, setLogos] = useState([]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (e) => {
    e.preventDefault();
    addDoc(collection(firestore, "waitingList"), {
      email: email,
      postedOn: serverTimestamp(),
    });
    setEmail("");
    setShowSuccess(true);
  };

  const handleButtonClick = () => {
    const targetSection = "contact-us";
    window.location.href = `#${targetSection}`;
  };

  const fetchLogos = async () => {
    const allLogos = query(
      collection(firestore, "landingPageLogos"),
      orderBy("name", "asc")
    );
    const documentSnapshots = await getDocs(allLogos);
    const tempLogos = documentSnapshots.docs.map((logo) => ({
      ...logo.data(),
      id: logo.id,
    }));
    setLogos(tempLogos);
  };

  useEffect(() => {
    fetchLogos();
  }, []);

  return (
    <Box className={classes.landingPage}>
      <Grid container direction={"row"}>
        <Grid
          container
          direction={"column"}
          lg={5}
          pl={mobile ? "" : "100px"}
          pt={mobile ? "" : "100px"}
        >
          <Typography
            variant="h1"
            fontSize={mobile ? "36px" : "52px"}
            fontWeight={700}
            mb={"36px"}
          >
            Experience the convenience of cashless tipping
          </Typography>
          <Typography
            variant="h2"
            fontSize={"16px"}
            fontWeight={500}
            mb={"36px"}
            color={"#696A6D"}
            lineHeight={"28.16px"}
            maxWidth={"452px"}
          >
            Tipper makes it easy to show your Appreciation! Say goodbye to loose
            change and hello to hassle-free tipping with Tipper!
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              placeholder="Your business's email address"
              variant="outlined"
              type="email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={classes1.searchTextField}
              style={{
                width: "300px",
                marginRight: "14px",
                marginBottom: mobile ? "14px" : "",
              }}
            />
            <RoundedButton text="Register" customPadding={true} />

            {showSuccess && (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0 80px 36px 0",
                }}
              >
                Email successfully registered!
              </div>
            )}
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          style={{
            display: "flex",
            direction: "rtl",
          }}
        >
          <img
            src={mainImage}
            alt="main-landing-page"
            style={{
              minHeight: "365px",
              minWidth: "400px",
              maxHeight: "773.04px",
              maxWidth: "846.93px",
            }}
          />
        </Grid>
      </Grid>

      {/* second row */}
      <Grid container justifyContent="center" className={classes.row}>
        <Grid item xs={12} md={12}>
          <Typography
            fontSize={mobile ? "24px" : "36px"}
            fontWeight={700}
            justifyContent={"center"}
            display={"flex"}
            mb={mobile ? "24px" : "65px"}
          >
            Why Tipper?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WhyTipperImage />
        </Grid>
      </Grid>

      {/* third row */}
      <Grid
        container
        className={classes.thirdRow}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12}></Grid>
        <Typography
          fontSize={mobile ? "24px" : "36px"}
          fontWeight={700}
          justifyContent={"center"}
          display={"flex"}
          mt={mobile ? "24px" : "65px"}
        >
          Trusted by leading brands
        </Typography>
        <Typography
          fontSize={"16px"}
          lineHeight={"28.16px"}
          fontWeight={600}
          variant="h2"
          color={"#696A6D"}
          my={1}
          textAlign={"center"}
        >
          Making the lives of restaurants, spas, cafés, bars, pubs and hotels
          easier
        </Typography>
      </Grid>

      <Grid>
        <Slider
          width="250px"
          duration={25}
          pauseOnHover={true}
          blurBorders={false}
        >
          {logos.map((img) => (
            <Slider.Slide>
              <img src={img.logo} alt={img.name} />
            </Slider.Slide>
          ))}
        </Slider>
      </Grid>

      <Grid
        container
        className={classes.thirdRow}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <BorderButton onClick={handleButtonClick} text="Get in Touch" />
      </Grid>

      {/* fourth row */}
      <Grid container justifyContent="center" className={classes.row}>
        <Grid item xs={12} md={12}>
          <Typography
            fontSize={mobile ? "24px" : "36px"}
            fontWeight={700}
            justifyContent={"center"}
            display={"flex"}
            mt={mobile ? "24px" : "65px"}
          >
            How it works?
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <HowItWorksImage />
        </Grid>
      </Grid>

      {/* fifth row */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.row}
      >
        <Typography
          fontSize={mobile ? "24px" : "36px"}
          fontWeight={700}
          justifyContent={"center"}
          display={"flex"}
          mt={mobile ? "24px" : "65px"}
        >
          The Platform you can Trust
        </Typography>
        <Typography
          fontSize={"16px"}
          lineHeight={"28.16px"}
          fontWeight={600}
          variant="h2"
          color={"#696A6D"}
          my={1}
          textAlign={"center"}
        >
          Tipping with Tipper is safe and secure, with bank-level encryption and
          data protection
        </Typography>
        <Box
          style={{
            marginTop: mobile ? 70 : 140,
          }}
        >
          <PlatformYouTrustImage />
        </Box>
      </Grid>
    </Box>
  );
};

export default LandingPage;
